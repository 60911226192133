import React from "react";
import { Layout, PortfolioNav, PortfolioHeader } from "../../components";
import locales from "../../constants";

const Hogra = ({ pageContext: { slug } }) => {
  const nav = {
    initialSlideIndex: 0,
  };
  const lang = "pl";
  return (
    <Layout
      seo={{
        title: "Hogra",
        href: slug,
        lang: "pl",
      }}
      header={{
        background:
          "linear-gradient( 225deg, #e9c49d, #e3bd95, #dbb286, #d2a576, #c69a6b )",
        icons: "#dbb286",
        navClass: "hogra",
        ogImage: require("../../assets/img/portfolio/hogra_top_back.jpg"),
      }}
      langSwitch={{
        langKey: "en",
        langSlug: "/en/projects/hogra/",
      }}
    >
      <PortfolioHeader name="hogra" height="196" />
      <section className="container-fluid hogra-section-1" id="info">
        <div className="row">
          <div className="col-md-6">
            <div className="inner">
              <h1>Hogra</h1>
              <ul>
                <li>Strona internetowa</li>
              </ul>
            </div>
          </div>
          <div className="col-md-6">
            <div className="inner inner--divider">
              <p>
                Dla międzynarodowej agencji Hogra, mającej swoją główną siedzibę
                na Wyspach Owczych, która jest naszym Partnerem, zakodowaliśmy w
                nowoczesnej i szybkiej technologii GatsbyJS przygotowany przez
                firmę projekt graficzny nowej strony www.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid hogra-section-2">
        <div className="row">
          <div className="col-md-12 text-center">
            <div className="scroll_wrapper scroll_wrapper--monitor scroll_wrapper--monitor-2">
              <div className="img_wrapper">
                <img
                  src={require("../../assets/img/portfolio/hogra_full.jpg")}
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid hogra-section-3">
        <div className="row">
          <div className="col-md-7 img-col">
            <img
              src={require("../../assets/img/portfolio/hogra_img_1.png")}
              alt=""
              className="img-fluid hogra-img-1"
            />
          </div>
          <div className="col-md-5 img-col">
            <img
              src={require("../../assets/img/portfolio/hogra_img_2.png")}
              alt=""
              className="img-fluid hogra-img-2"
            />
          </div>
        </div>
      </section>

      <section className="container-fluid no-gutters hogra-section-4">
        <div className="row no-gutters">
          <div className="col-md-4 offset-md-8">
            <img
              src={require("../../assets/img/portfolio/hogra_img_3.png")}
              alt=""
              className="img-fluid hogra-img-3"
            />
          </div>
        </div>
      </section>

      <section className="container-fluid no-gutters hogra-section-5">
        <div className="row no-gutters">
          <div className="col-md-5">
            <img
              src={require("../../assets/img/portfolio/hogra_img_4.png")}
              alt=""
              className="img-fluid hogra-img-4"
            />
          </div>
        </div>
      </section>

      <section className="container-fluid no-gutters hogra-section-6">
        <div className="row no-gutters">
          <div className="col-md-5 offset-md-7">
            <img
              src={require("../../assets/img/portfolio/hogra_img_5.png")}
              alt=""
              className="img-fluid hogra-img-5"
            />
          </div>
        </div>
      </section>

      <PortfolioNav data={nav} locales={locales[lang]} />
    </Layout>
  );
};
export default Hogra;
